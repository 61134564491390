import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { DarkBoxWithPosition } from '../common-code-dashboards/components/DarkBoxWithPosition'
import { useCaoDeviceData } from '../hooks/useCaoDeviceData'
import useDashboardSize from '../common-code-dashboards/hooks/useDashboardSize'
import { DEFAULT_WIDTH } from '../common-code-dashboards/constants/defaults'

const BOX_WIDTH = 230
const POSITION_LEFT = 2276
const POSITION_TOP = 1282

export function TemperatureAndHumidity(): JSX.Element | null {
  const { t } = useTranslation()
  const { fontSizeTranslation } = useFontSizeTranslation()
  const { data: caoDeviceData } = useCaoDeviceData()
  const { dashboardWidth } = useDashboardSize()

  if (!caoDeviceData) {
    return null
  }

  const paddingTopBottom = `${(dashboardWidth * 20) / DEFAULT_WIDTH}px`
  const paddingSides = `${(dashboardWidth * 20) / DEFAULT_WIDTH}px`

  return (
    <>
      {caoDeviceData.temperature && (
        <DarkBoxWithPosition
          boxWidth={BOX_WIDTH}
          positionLeft={POSITION_LEFT}
          positionTop={POSITION_TOP}
          sx={{ padding: `${paddingTopBottom} ${paddingSides}` }}
        >
          <Typography
            align='left'
            sx={{
              fontSize: fontSizeTranslation('36px'),
            }}
          >
            {t('Temperature')}
          </Typography>
          <Typography
            align='left'
            sx={{
              fontSize: fontSizeTranslation('60px'),
            }}
          >
            {`${Math.round(caoDeviceData.temperature.value)} ${caoDeviceData.temperature.unit}`}
          </Typography>
        </DarkBoxWithPosition>
      )}
      {caoDeviceData.rh && (
        <DarkBoxWithPosition
          boxWidth={BOX_WIDTH}
          positionLeft={POSITION_LEFT + 250}
          positionTop={POSITION_TOP}
          sx={{ padding: `${paddingTopBottom} ${paddingSides}` }}
        >
          <Typography
            align='left'
            sx={{
              fontSize: fontSizeTranslation('36px'),
            }}
          >
            {t('Humidity')}
          </Typography>
          <Typography
            align='left'
            sx={{
              fontSize: fontSizeTranslation('60px'),
            }}
          >
            {`${caoDeviceData.rh.value} ${caoDeviceData.rh.unit}`}
          </Typography>
        </DarkBoxWithPosition>
      )}
    </>
  )
}
