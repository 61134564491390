import React from 'react'
import { useCalculatedAirQuality } from '../hooks/useCalculatedAirQuality'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CamfilLogo } from '../common-code-dashboards/components/CamfilLogo'
import { BackgroundPoorToExcellent } from '../common-code-dashboards/components/backgroundPoorToExcellent/BackgroundPoorToExcellent'
import { AirQualityCircle } from '../common-code-dashboards/components/airQualityCircle/AirQualityCircle'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { CaoLogo } from './CaoLogo'
import { IndoorAirQualityBox } from './IndoorAirQualityBox'
import { OutdoorAirQualityBox } from './OutdoorAirQualityBox'
import { AqStandardBox } from './AqStandardBox'
import { TemperatureAndHumidity } from './TemperatureAndHumidity'
import { InsideCircle } from './InsideCircle'
import { InfoHeader } from './InfoHeader'

export function Dashboard(): JSX.Element {
  const { airQuality, isLoading } = useCalculatedAirQuality()
  const { t } = useTranslation()
  const { fontSizeTranslation } = useFontSizeTranslation()

  return (
    <BackgroundPoorToExcellent airQualityPercentage={airQuality}>
      <CamfilLogo />
      <CaoLogo />

      {airQuality !== undefined && (
        <>
          <AirQualityCircle airQualityPercentage={airQuality} />
          <InsideCircle />

          <InfoHeader />
          <IndoorAirQualityBox />
          <OutdoorAirQualityBox />
          <AqStandardBox />
          <TemperatureAndHumidity />
        </>
      )}

      {airQuality === undefined && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <Typography
              sx={{
                fontSize: fontSizeTranslation('60px'),
                fontWeight: 'bold',
                lineHeight: 1,
              }}
            >
              {t('No_Data')}
            </Typography>
          )}
        </Box>
      )}
    </BackgroundPoorToExcellent>
  )
}
