export interface QueryParams {
  sensorId: string | null
  pm25: number | null
  target: number | null
  targetName: string | null
}

export function parseUrlQueryParams(query: URLSearchParams): QueryParams {
  const sensorId = query.get('id')

  const pm25String = query.get('pm25')
  const pm25 = pm25String ? Number.parseInt(pm25String) : null

  const targetString = query.get('target')
  const target = targetString ? Number.parseFloat(targetString) : null

  const targetName = query.get('targetName')

  return {
    sensorId,
    pm25,
    target,
    targetName,
  }
}
