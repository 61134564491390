export interface Props {
  id: string
  percentage: number
  color: string
}

export function ThinPercentageBarSVG({ id, percentage, color }: Props) {
  percentage = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage

  const width = 100
  const height = 4
  const viewBox = `0 0 ${width} ${height}`
  const rectY = 0
  const percentageWidth = (percentage * width) / 100
  const mask = `hideOverflow${id}`
  const clipPath = `clipPath${id}`

  return (
    <svg width='100%' height='100%' viewBox={viewBox}>
      <defs>
        <clipPath id={clipPath}>
          <rect y={rectY} width={percentageWidth} height={height} rx={height / 2} />
        </clipPath>
        <mask id={mask}>
          <circle cx={height / 2} cy='50%' r={height / 2} fill='white' clipPath={`url(#${clipPath})`} />
          {percentageWidth - height / 2 > 0 && (
            <rect
              y={rectY}
              x={height / 2}
              width={percentageWidth - height / 2}
              height={height}
              fill='white'
              clipPath={`url(#${clipPath})`}
            />
          )}
        </mask>
      </defs>
      <line x1='0' y1={height / 2} x2={width} y2={height / 2} stroke='#102F3C' strokeWidth={1} />
      <rect y={rectY} width={percentageWidth} height={height} rx={height / 2} fill={color} mask={`url(#${mask})`} />
      <rect y={rectY} width={width} height={height} rx={height / 2} fill='none' />
    </svg>
  )
}
