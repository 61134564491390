import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { BoxWithPosition } from '../common-code-dashboards/components/BoxWithPosition'
import useDashboardSize from '../common-code-dashboards/hooks/useDashboardSize'
import { DEFAULT_WIDTH } from '../common-code-dashboards/constants/defaults'
import { Typography } from '@mui/material'

export function InfoHeader(): JSX.Element {
  const { t } = useTranslation()
  const { fontSizeTranslation } = useFontSizeTranslation()
  const { dashboardWidth } = useDashboardSize()

  const marginBottom = `${(dashboardWidth * 40) / DEFAULT_WIDTH}px`

  return (
    <>
      <BoxWithPosition boxWidth={480} positionLeft={2276} positionTop={359}>
        <Typography align='center' fontSize={fontSizeTranslation('36px')}>
          PM 2.5
        </Typography>
      </BoxWithPosition>

      <BoxWithPosition boxWidth={600} positionLeft={2276 + 240} positionTop={413} center>
        <Typography align='center' marginBottom={marginBottom} fontSize={fontSizeTranslation('60px')}>
          {t('Live_Particle_Count')}
        </Typography>
      </BoxWithPosition>
    </>
  )
}
