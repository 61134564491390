import useSWR, { SWRResponse } from 'swr'

import { parseUrlQueryParams } from '../utils/parseUrlQueryParams'
import { CaoDeviceData } from '../common-code-react/common-types/products/cao'
import { fetcherExternal } from '../common-code-react/utils/fetcherExternal'
import { useQuery } from '../common-code-react/hooks/useQuery'

export function useCaoDeviceData(): SWRResponse<CaoDeviceData> {
  const query = useQuery()
  const queryParams = parseUrlQueryParams(query)

  const fullUrl = queryParams.sensorId
    ? encodeURI(
        `${process.env.REACT_APP_CAO_SENSOR_API}/api/eclog/v1/sensors/${queryParams.sensorId}?apiKey=${process.env.REACT_APP_CAO_SENSOR_API_KEY}`,
      )
    : null

  const swrResponse = useSWR(fullUrl, fetcherExternal<CaoDeviceData>(), {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 30000,
  })

  if (swrResponse.data?.['pm2.5'] && queryParams.pm25 !== null) {
    swrResponse.data['pm2.5'].value = queryParams.pm25
  }

  return swrResponse
}
