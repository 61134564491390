import { Box, Typography } from '@mui/material'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { DarkBoxWithPosition } from '../common-code-dashboards/components/DarkBoxWithPosition'
import useDashboardSize from '../common-code-dashboards/hooks/useDashboardSize'
import { DEFAULT_WIDTH } from '../common-code-dashboards/constants/defaults'

export function AqStandardBox(): JSX.Element | null {
  const { fontSizeTranslation } = useFontSizeTranslation()
  const { dashboardWidth } = useDashboardSize()

  const lineHeightLeft = `${(dashboardWidth * 45) / DEFAULT_WIDTH}px`
  const paddingTopBottom = `${(dashboardWidth * 20) / DEFAULT_WIDTH}px`
  const paddingSides = `${(dashboardWidth * 20) / DEFAULT_WIDTH}px`

  return (
    <DarkBoxWithPosition
      boxWidth={480}
      positionLeft={2276}
      positionTop={1083}
      sx={{ padding: `${paddingTopBottom} ${paddingSides}` }}
    >
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        paddingTop={`${(dashboardWidth * 5) / DEFAULT_WIDTH}px`}
        alignItems='end'
      >
        <Typography
          sx={{
            fontSize: fontSizeTranslation('36px'),
            lineHeight: lineHeightLeft,
          }}
        >
          WHO recommendation (annual):
        </Typography>
        <Typography
          align='right'
          sx={{
            whiteSpace: 'nowrap',
            fontSize: fontSizeTranslation('64px'),
          }}
        >
          {5}
          <Typography component='span' fontSize={fontSizeTranslation('40px')}>
            &#181;g/m&sup3;
          </Typography>
        </Typography>
      </Box>
    </DarkBoxWithPosition>
  )
}
