import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOpenAqMeasurement } from '../hooks/useOpenAqMeasurement'
import { AirQualityBox } from './AirQualityBox'

const MAX_VALUE = 50

export function OutdoorAirQualityBox(): JSX.Element | null {
  const { t } = useTranslation()
  const { openAqPm25Data } = useOpenAqMeasurement()

  if (!openAqPm25Data || !openAqPm25Data.value) {
    return null
  }

  return (
    <AirQualityBox
      positionTop={801}
      label={t('Outdoor_Air_Quality')}
      value={openAqPm25Data.value}
      maxValue={MAX_VALUE}
    />
  )
}
