import { useState, useEffect } from 'react'
import { useCaoDeviceData } from './useCaoDeviceData'
import { calculateIAQ } from '../utils/iaqUtils'
import { DATA_TIMEOUT_SECONDS } from '../constants'

export interface IaqData {
  iaq: number
  timestamp: number
}

interface AirQualityReturnData {
  airQuality: number | undefined
  isLoading: boolean
  isError: boolean
}

export function useCalculatedAirQuality(): AirQualityReturnData {
  const [airQuality, setAirQuality] = useState<number | undefined>()

  const [pm25, setPm25] = useState<number | undefined>()
  const [lastTimestamp, setLastTimestamp] = useState<number>(0)

  const { data: caoDeviceData, error: caoDeviceDataError } = useCaoDeviceData()

  useEffect(() => {
    console.log(`caoDeviceData: ${JSON.stringify(caoDeviceData)}`)
    if (
      caoDeviceData &&
      caoDeviceData['pm2.5'] &&
      caoDeviceData['pm2.5'].timestamp &&
      caoDeviceData['pm2.5'].timestamp !== lastTimestamp
    ) {
      setPm25(caoDeviceData['pm2.5'].value)
      setLastTimestamp(caoDeviceData['pm2.5'].timestamp)
    }
  }, [caoDeviceData])

  useEffect(() => {
    if (pm25) {
      setAirQuality(calculateIAQ(pm25))
    }
  }, [pm25])

  const nowEpoch = Math.round(Date.now() / 1000)

  return {
    airQuality: nowEpoch - lastTimestamp < DATA_TIMEOUT_SECONDS ? airQuality : undefined,
    isLoading: !caoDeviceData && !caoDeviceDataError,
    isError: caoDeviceDataError,
  }
}
