import Box from '@mui/material/Box'
import CaoLogoImg from '../assets/cao-logo.png'
import useDashboardSize from '../common-code-dashboards/hooks/useDashboardSize'

export function CaoLogo(): JSX.Element {
  const { dashboardWidth, dashboardHeight } = useDashboardSize()
  const width = Math.round(dashboardHeight * 0.34)
  const positionTop = Math.round(dashboardHeight * 0.15)
  const positionLeft = Math.round(dashboardWidth * 0.025)

  return (
    <Box
      component='img'
      sx={{
        position: 'absolute',
        top: positionTop,
        left: positionLeft,
        width: width,
      }}
      src={CaoLogoImg}
    />
  )
}
