import useSWRImmutable from 'swr/immutable'
import { parseUrlQueryParams } from '../utils/parseUrlQueryParams'
import { SWRResponse } from 'swr'
import { DeviceSettingsInfo } from '../common-code-react/common-types/device/deviceSettings'
import { fetcher } from '../common-code-react/utils/fetcher'
import { useQuery } from '../common-code-react/hooks/useQuery'

export function useDeviceSettingsInfo(): SWRResponse<DeviceSettingsInfo> {
  const query = useQuery()
  const queryParams = parseUrlQueryParams(query)

  const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_DEVICE_INFO_API_URL || '' : ''

  const url = queryParams.sensorId ? `/api/caodevices/${queryParams.sensorId}` : null
  const fullUrl = url ? encodeURI(`${baseUrl}${url}`) : null

  return useSWRImmutable(fullUrl, fetcher<DeviceSettingsInfo>())
}
