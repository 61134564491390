import useSWR from 'swr'
import { useEffect, useState } from 'react'
import { useDeviceSettingsInfo } from './useDeviceSettingsInfo'
import { useCaoDeviceData } from './useCaoDeviceData'
import { GeoLocationInfo } from '../common-code-react/common-types/modules/geoLocationInfo'
import { fetcherExternal } from '../common-code-react/utils/fetcherExternal'

interface MeasurementObject {
  parameter: string
  value: number
  lastUpdated: string
  unit: string
}

interface CoordinatesObject {
  latitude: number
  longitude: number
}

interface ResultObject {
  location: string
  city: string
  country: string
  coordinates: CoordinatesObject
  measurements: MeasurementObject[]
}

interface MetaObject {
  license: string
  website: string
  page: number
  limit: number
  found: number
}

export interface OpenAqLatestData {
  meta: MetaObject
  results: ResultObject[]
}

export interface OpenAqPm25Data {
  value: number
}

export function useOpenAqMeasurement() {
  const { data: deviceSettingsInfo } = useDeviceSettingsInfo()
  const { data: caoDeviceData } = useCaoDeviceData()

  const [openAqPm25Data, setOpenAqPm25Data] = useState<OpenAqPm25Data | undefined>()

  const reportedGeoLocation: GeoLocationInfo | undefined =
    caoDeviceData?.latitude && caoDeviceData?.longitude
      ? {
          lat: caoDeviceData?.latitude.value,
          lng: caoDeviceData?.longitude.value,
        }
      : undefined

  const location = deviceSettingsInfo?.deviceSettings?.geoLocationInfo || reportedGeoLocation || undefined

  console.log(`location: ${JSON.stringify(location)}`)

  const fullUrl =
    location && location.lat && location.lng
      ? encodeURI(
          `${process.env.REACT_APP_OPEN_AQ_API_URL}/latest?coordinates=${location.lat.toFixed(
            8,
          )},${location.lng.toFixed(8)}&radius=${process.env.REACT_APP_OPEN_AQ_RADIUS_METERS}`,
        )
      : null

  const ONE_MINUTE = 60000
  const FIVE_MINUTES = 10 * ONE_MINUTE

  const { data, error } = useSWR(fullUrl, fetcherExternal<OpenAqLatestData>(), {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: FIVE_MINUTES,
  })

  useEffect(() => {
    if (data) {
      console.log(`latestAqData: ${JSON.stringify(data)}`)

      let newPm25Data: number | undefined = undefined
      let i = 0
      while (!newPm25Data && i < data.results.length) {
        const pm25Measurement = data.results[i].measurements.find((measurement) => measurement.parameter === 'pm25')
        if (pm25Measurement && pm25Measurement.value > 0) {
          newPm25Data = pm25Measurement.value
        }
        i = i + 1
      }

      if (newPm25Data) {
        setOpenAqPm25Data({ value: newPm25Data })
      }
    } else {
      setOpenAqPm25Data(undefined)
    }
  }, [data])

  return {
    openAqPm25Data,
    isLoading: !error && !data,
    isError: error,
  }
}
