import React from 'react'
import { Box, Typography } from '@mui/material'
import { useFontSizeTranslation } from '../common-code-dashboards/hooks/useFontSizes'
import { ColoredQualityLabel } from '../common-code-dashboards/components/ColoredQualityLabel'
import { DarkBoxWithPosition } from '../common-code-dashboards/components/DarkBoxWithPosition'
import useDashboardSize from '../common-code-dashboards/hooks/useDashboardSize'
import { DEFAULT_WIDTH } from '../common-code-dashboards/constants/defaults'
import { ThinPercentageBarSVG } from './ThinPercentageBarSVG'
import { getColor } from '../common-code-dashboards/utils/getColor'

export interface Props {
  positionTop: number
  label: string
  value: number
  maxValue: number
}

export function AirQualityBox({ positionTop, label, value, maxValue }: Props): JSX.Element | null {
  const { fontSizeTranslation } = useFontSizeTranslation()

  const { dashboardWidth } = useDashboardSize()

  const paddingTopBottom = `${(dashboardWidth * 20) / DEFAULT_WIDTH}px`
  const paddingSides = `${(dashboardWidth * 20) / DEFAULT_WIDTH}px`

  return (
    <DarkBoxWithPosition
      boxWidth={480}
      positionLeft={2276}
      positionTop={positionTop}
      sx={{ padding: `${paddingTopBottom} ${paddingSides}` }}
    >
      <ColoredQualityLabel airQualityPercentage={((maxValue - value) / maxValue) * 100} fontSize='36px' />
      <Typography
        align='center'
        sx={{
          fontSize: fontSizeTranslation('36px'),
          lineHeight: fontSizeTranslation('50px'),
        }}
      >
        {label}
      </Typography>
      <Typography
        align='center'
        sx={{
          fontSize: fontSizeTranslation('96px'),
          lineHeight: 1.1,
        }}
      >
        {value.toFixed(1)}
        <Typography component='span' fontSize={fontSizeTranslation('40px')}>
          &#181;g/m&sup3;
        </Typography>
      </Typography>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <ThinPercentageBarSVG
          id={label}
          percentage={(value / maxValue) * 100}
          color={getColor(((maxValue - value) / maxValue) * 100)}
        />
      </Box>
    </DarkBoxWithPosition>
  )
}
