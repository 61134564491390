import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCaoDeviceData } from '../hooks/useCaoDeviceData'
import { AirQualityBox } from './AirQualityBox'

const MAX_VALUE = 50

export function IndoorAirQualityBox(): JSX.Element | null {
  const { t } = useTranslation()
  const { data: caoDeviceData } = useCaoDeviceData()

  if (!caoDeviceData || !caoDeviceData['pm2.5'] || !caoDeviceData['pm2.5'].value) {
    return null
  }

  return (
    <AirQualityBox
      positionTop={520}
      label={t('Indoor_Air_Quality')}
      value={caoDeviceData['pm2.5'].value}
      maxValue={MAX_VALUE}
    />
  )
}
